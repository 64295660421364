<template>
	<v-subheader v-if="model">{{ text }}</v-subheader>
</template>

<script>
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'SelectionCount',
	mixins: [DocumentsManagerModuleGuard],
	props: {
		value: {
			type: Array,
			required: false,
			default: null
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		items: function () {
			return this.model
		},
		documents: function () {
			return this.items?.length > 0 ? this.items.filter(item => item?.is_document) : []
		},
		totalSize: function () {
			let result = this.documents.map(document => document.latestVersion?.size).reduce((sum, size) => sum + parseInt(size), 0)
			return result > 0 ? this.$humanReadableSize(result) : null
		},
		text: function () {
			let result = this.$tc('ecm.selected_items', this.items?.length, { count: this.items?.length })
			if (this.totalSize) {
				result += ' (' + this.totalSize + ')'
			}
			return result
		}
	}
}
</script>
