<template>
	<v-layout fill-height>
		<v-layout v-if="!structureIsBuilding && $vuetify.breakpoint.smAndDown" row align-center justify-center fill-height style="width: 100%">
			<v-layout v-show="!showDetailsOnMobile" column align-center justify-center fill-height style="width: 100%">
				<v-flex style="width: 100%">
					<DocumentsToolbar
						v-model="selection"
						:selected-folder="selectedFolder"
						:show-details="manualShowDetails"
						@selection-changed="selectFolder($event)"
						@do-show-details="manualShowDetails = $event"
					/>
				</v-flex>
				<v-divider />
				<v-progress-linear v-if="loading" height="3px" style="margin: 0 !important" indeterminate />
				<v-flex fill-height style="width: 100%" scroll-y>
					<FolderContent v-model="displayedFolder" :selection="selectedElements" @selection-changed="updateSelectedElements($event)" />
				</v-flex>
			</v-layout>
			<v-layout v-show="showDetailsOnMobile" row align-center justify-center fill-height>
				<SelectionDetails v-model="selection" :selected-folder="displayedFolder" @close="showDetailsOnMobile = false" />
			</v-layout>
		</v-layout>
		<v-layout v-else-if="!structureIsBuilding && $vuetify.breakpoint.mdAndUp" column fill-height>
			<w-flex shrink style="width: 100%">
				<DocumentsToolbar
					v-model="selection"
					:selected-folder="selectedFolder"
					:show-details="manualShowDetails"
					@selection-changed="selectFolder($event)"
					@show-details="manualShowDetails = $event"
				/>
			</w-flex>
			<v-divider style="width: 100%" />
			<v-progress-linear v-if="loading" height="3px" style="margin: 0 !important; width: 100%" indeterminate />
			<w-flex fill-height scroll-y overflow-x-hidden style="width: 100%">
				<w-layout row align-start justify-start fill-height style="width: 100%" resizable-drawer="width">
					<w-flex
						resizable
						data-resizable="width"
						:min-width="resizableWidthSettings.minTreeView"
						:initial-width="resizableWidthSettings.initialTreeView"
						custom-width-saved-as="custom-width-tree-view"
						fill-height
						scroll-y
					>
						<DocumentsTreeview v-model="treeStructure" :selected-folder="selectedFolder" @selection-changed="selectFolder($event)" />
					</w-flex>
					<w-flex fill-height resizable-constraint="width" :style="{ 'min-width': resizableWidthSettings.minFolderContent + 'px' }">
						<FolderContent v-model="displayedFolder" :selection="selectedElements" @selection-changed="updateSelectedElements($event)" />
					</w-flex>
					<w-flex
						v-if="showDetailsOnDesktop"
						class="item-details-panel"
						resizable
						fill-height
						data-resizable="width"
						:min-width="resizableWidthSettings.minItemDetails"
						:initial-width="resizableWidthSettings.initialItemDetails"
						custom-width-saved-as="custom-width-item-details"
						:style="{ 'min-width': resizableWidthSettings.initialItemDetails + 'px', 'max-width': resizableWidthSettings.initialItemDetails + 'px' }"
					>
						<SelectionDetails v-model="selection" :selected-folder="displayedFolder" fill-height scroll-y @close="manualShowDetails = false" />
					</w-flex>
				</w-layout>
			</w-flex>
		</v-layout>
		<w-flex v-if="structureIsBuilding" fill-height>
			<v-layout row align-center fill-height style="width: 100%">
				<w-info :text="$t('errors.building_structure')" :outline="true" />
			</v-layout>
		</w-flex>
		<DocumentsManagerPreview :folder="displayedFolder" :is-loading="loading" :value="documentHash" :version-id="versionId" />
	</v-layout>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'

import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'
import CompaniesList from '@/components/Documents/CompaniesList'
import TreeStructureService from '../services/TreeStructures/TreeStructureService'

import HandleSideBarMixin from '@/mixins/SideBar/HandleSideBarMixin'
import ResizableMixin from '@/mixins/ResizableMixin'

import { Document } from '@/classes/Documents/Document.class'
import { Folder } from '@/classes/Documents/Folder.class'

export default {
	name: 'DocumentsManager',
	components: {
		DocumentsManagerPreview: () => ({
			component: import('@/components/Documents/DocumentsManagerPreview')
		}),
		DocumentsTreeview: () => ({
			component: import('@/components/Documents/DocumentsTreeview')
		}),
		DocumentsToolbar: () => ({
			component: import('@/components/Documents/DocumentsToolbar')
		}),
		FolderContent: () => ({
			component: import('@/components/Documents/FolderContent')
		}),
		SelectionDetails: () => ({
			component: import('@/components/Documents/SelectionDetails')
		})
	},
	mixins: [DocumentsManagerModuleGuard, HandleSideBarMixin, ResizableMixin],
	props: {
		documentHash: {
			default: null,
			required: false,
			type: String
		},
		folderHash: {
			default: null,
			required: false,
			type: String
		},
		versionId: {
			default: null,
			required: false,
			type: Number
		}
	},
	data: function () {
		return {
			initialized: false,
			fetchingData: 0,
			reseting: false,
			selectedFolder: null,
			selectedElements: [],
			showDetailsOnMobile: false,
			manualShowDetails: true,
			selectionInProgress: false,
			dataLoadingTimeout: null,
			lastParameters: null,
			isFromSearch: false,
			structureIsBuilding: false
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant,
			isFiltering: state => state.documents.isFiltering,
			treeStructure: state => state.documents.treeStructure,
			ecmAutoRefresh: state => state.globalParameter.ecmAutoRefresh
		}),
		resizableWidthSettings: function () {
			let minFolderContent
			let minTreeView
			let initialTreeView
			let minItemDetails
			let initialItemDetails
			switch (this.$vuetify.breakpoint.name) {
				case 'xl':
					minFolderContent = 800
					minTreeView = 220
					initialTreeView = '20%'
					minItemDetails = 200
					initialItemDetails = '16.5vw'
					break
				case 'lg':
					minFolderContent = 500
					minTreeView = 220
					initialTreeView = '20%'
					minItemDetails = 200
					initialItemDetails = '16.5vw'
					break
				case 'md':
					minFolderContent = 400
					minTreeView = 220
					initialTreeView = '20%'
					minItemDetails = 200
					initialItemDetails = '20vw'
					break
				case 'sm':
				case 'xs':
				default:
					minFolderContent = 300
					minTreeView = 220
					initialTreeView = '20%'
					minItemDetails = 200
					initialItemDetails = '20vw'
					break
			}
			return {
				minFolderContent: minFolderContent,
				minTreeView: minTreeView,
				initialTreeView: initialTreeView,
				minItemDetails: minItemDetails,
				initialItemDetails: initialItemDetails
			}
		},
		showPreview: function () {
			return !this.loading && this.documentHash
		},
		filterValidated: function () {
			return this.$store.state.documents.filter.validated
		},
		filterUnvalidated: function () {
			return this.$store.state.documents.filter.unvalidated
		},
		validationFilter: function () {
			let result = 'all'

			if (this.filterValidated && !this.filterUnvalidated) {
				result = 'validated'
			} else if (!this.filterValidated && this.filterUnvalidated) {
				result = 'unvalidated'
			}

			return result
		},
		searchedName: {
			get: function () {
				return this.$store.state.documents.filter.search.filename
			},
			set: function (newValue) {
				let result
				const filtering = newValue != null
				if (filtering) {
					this.service.setSearchFilename(newValue).then(() => {
						return this.service.setIsFiltering(true)
					})
				} else {
					this.service.setIsFiltering(false).then(() => {
						return this.service.setSearchFilename(null)
					})
				}
				return result
			}
		},
		searchResults: {
			get: function () {
				return this.$store.state.documents.filter.search.results
			},
			set: function (newValue) {
				let result
				const filtering = newValue != null
				if (filtering) {
					this.service.setSearchResults(newValue).then(() => {
						return this.service.setIsFiltering(true)
					})
				} else {
					this.service.setIsFiltering(false).then(() => {
						return this.service.setSearchResults(null)
					})
				}
				return result
			}
		},
		displayedFolder: {
			get: function () {
				let result
				if (this.isFiltering) {
					result = this.searchResults
				} else {
					result = this.selectedFolder
				}
				return result
			},
			set: function (newValue) {
				this.isFromSearch = this.isFiltering
				this.service.resetSearchFilter()
				this.selectFolder(newValue)
			}
		},
		loading: function () {
			return this.fetchingData > 0
		},
		selection: {
			get: function () {
				let result
				if (this.selectedElements && this.selectedElements.length > 0) {
					result = this.selectedElements
				} else if (this.selectedFolder && !this.selectedFolder.is_root && !this.selectedFolder.is_trash) {
					result = [this.selectedFolder]
				} else {
					result = []
				}
				return result
			},
			set: function (value) {
				if (Array.isArray(value) || !value) {
					this.updateSelectedElements(value)
				} else {
					this.updateSelectedElements([value])
				}
			}
		},
		showDetailsOnDesktop: function () {
			return (
				this.treeStructure.fetched &&
				this.manualShowDetails &&
				(this.selectedFolder || this.selectedElements.length > 0) &&
				((!this.selectedFolder?.is_root && !this.selectedFolder?.is_trash) ||
					((!this.selectedFolder?.is_root || !this.selectionInProgress) &&
						this.selectedElements.length > 0 &&
						!this.selectedElements.some(node => node.is_root)))
			)
		}
	},
	watch: {
		accountingFirmId: {
			handler: function (newValue, oldValue) {
				if (newValue != oldValue) {
					this.initializeCompaniesList()
				}
			}
		},
		vendorId: {
			handler: function (newValue, oldValue) {
				if (!this.treeStructure.vendor || newValue != this.treeStructure.vendor.id) {
					if (newValue !== oldValue && this.appService.hasModule('workflow')) {
						this.loadVendorWorkflows(newValue)
					}
					this.reset().then(() => {
						this.fetchData()
						this.getTreeStructureIsBuilding()
					})
				}
			}
		},
		folderHash: {
			handler: function () {
				if (this.selectedFolder?.alias != 'root') {
					this.fetchData()
				}
			}
		},
		selectedFolder: {
			handler: async function (newValue, oldValue) {
				if (!this.reseting && (!newValue || !oldValue || newValue.hash != oldValue.hash || newValue.alias != oldValue.alias)) {
					this.service.resetSearchFilter()
					this.clearSelectedElements()
					this.updateURL()
				}
			}
		},
		searchedName: {
			handler: function (newValue, oldValue) {
				this.triggerFetchData(newValue, oldValue)
			}
		},
		validationFilter: {
			handler: function (newValue, oldValue) {
				this.triggerFetchData(newValue, oldValue)
			}
		},
		manualShowDetails: {
			handler: function () {
				this.resetResizableLayout()
			}
		},
		structureIsBuilding: {
			handler: function (value) {
				if (value) {
					this.service.reset()
				}
			}
		},
		// used to set the current selection to parent folder when user unselects a node item
		// @see https://trello.com/c/d3XF20Dy/6387-us-flux-de-travail-sur-r%C3%A9pertoire
		selection: {
			handler: function (value) {
				if (value.length > 0) {
					this.setCurrentSelection(value[0])
				}
			}
		}
	},
	mounted: function () {
		this.initializeCompaniesList()
		if (this.appService.hasModule('workflow') && !this.selectedFolder?.is_trash) {
			this.loadVendorWorkflows(this.vendorId)
		}
		this.fetchData()
		this.getTreeStructureIsBuilding()
	},
	destroyed: function () {
		this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
		this.setHasSidebar(false)
		this.setShowSidebar(false)
		this.reset()
	},
	methods: {
		...mapActions({
			loadVendorWorkflows: 'workflows/loadVendorWorkflows'
		}),
		...mapMutations({
			setDocuments: 'documents/preview/setDocuments',
			addDocument: 'documents/preview/addDocument',
			setCurrentDocumentByHash: 'documents/preview/setCurrentByHash',
			setCurrentSelection: 'documents/selection/setCurrent',
			setNodesWorkflows: 'workflows/setNodesWorkflows',
			resetWorkflows: 'workflows/reset'
		}),
		getAppEventsActionsMapping: function () {
			return [
				{ event: this.appEvents.UPLOAD_STARTED, action: this.onUploadStarted },
				{ event: this.appEvents.UPLOAD_ENDED, action: this.restartPollingDataLoad }
			]
		},
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.SHOW_MOBILE_DETAILS, action: this.showDetailsOnMobileDetails },
				{ event: this.events.SELECTION_START, action: this.selectionStart },
				{ event: this.events.SELECTION_STOP, action: this.selectionStop },
				{ event: this.events.DELETION_STARTED, action: this.onDeletionStarted },
				{ event: this.events.DELETION_ENDED, action: this.onDeletionEnded }
			]
		},
		initializeCompaniesList: function () {
			this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
			if (this.isAccountant) {
				const CompaniesListClass = Vue.extend(CompaniesList)
				const companiesListComponent = new CompaniesListClass({
					parent: this
				})
				this.appEventBus.emit(this.appEvents.SET_SIDEBAR_CONTENT, {
					canMinimize: this.$vuetify.breakpoint.mdAndUp,
					startHidden: this.$vuetify.breakpoint.smAndDown,
					canClose: this.$vuetify.breakpoint.smAndDown,
					title: this.$t('client_workspace.tasks.filters.companies'),
					moduleInstance: companiesListComponent,
					emitter: this
				})
				this.setHasSidebar(true)
				this.setShowSidebar(this.$vuetify.breakpoint.mdAndUp)
			}
		},
		triggerFetchData: function (newValue, oldValue) {
			if (
				(oldValue == null && newValue != null) ||
				(Array.isArray(newValue) &&
					Array.isArray(oldValue) &&
					(newValue.length != oldValue.length || (newValue.length > 0 && !newValue.every(value => oldValue.includes(value))))) ||
				(!Array.isArray(newValue) && !Array.isArray(oldValue) && newValue != oldValue && !this.isFromSearch)
			) {
				this.fetchData()
			}
		},
		getFetchParameters: function () {
			const parametersSet = {
				folder: ['has_sub_folders', 'counter'],
				folders: ['folders', 'folders.counter', 'folders.has_sub_folders'],
				documents: ['documents', 'documents.has_signatories']
			}
			const fields =
				this.validationFilter !== 'validated' && this.validationFilter !== 'unvalidated'
					? [...parametersSet.folder, ...parametersSet.folders, ...parametersSet.documents]
					: [...parametersSet.folder, ...parametersSet.documents]
			const result = {
				filters: {
					with_content: true
				},
				fields: fields
			}
			if (this.searchedName && this.searchedName.trim().length > 0) {
				result.filters['search'] = this.searchedName
			}
			if (this.validationFilter == 'validated') {
				result.filters['is_validated'] = true
			} else if (this.validationFilter == 'unvalidated') {
				result.filters['is_validated'] = false
			}
			return result
		},
		getIsFiltering: function () {
			const result = (this.searchedName && this.searchedName.trim().length > 0) || this.validationFilter != 'all'
			this.$store.dispatch('documents/setIsFiltering', result)
			return result
		},
		fetchData: function () {
			this.fetchingData++
			this.stopPollingDataLoad()
			let result
			if (!this.vendorId) {
				result = this.appService.selectDefaultVendor()
			} else {
				const parameters = this.getFetchParameters()
				const isFiltering = this.getIsFiltering()
				result = this.getFolder(this.folderHash, parameters, isFiltering)
			}
			return result.finally(() => {
				this.initialized = true
				this.fetchingData--
			})
		},
		onDeletionEnded() {
			this.eventBus.emit(this.events.preview.CLOSE)
			this.restartPollingDataLoad()
		},
		restartPollingDataLoad: function () {
			this.startPollingDataLoad(this.lastParameters)
		},
		startPollingDataLoad: function (parameters) {
			this.lastParameters = parameters
			this.stopPollingDataLoad()
			if (this.ecmAutoRefresh) {
				this.dataLoadingTimeout = setTimeout(() => {
					this.getFolder(this.folderHash, parameters, false, false, false).then(() => {
						this.startPollingDataLoad(parameters)
					})
				}, this.ecmAutoRefresh)
			}
		},
		stopPollingDataLoad: function () {
			if (this.dataLoadingTimeout) {
				clearTimeout(this.dataLoadingTimeout)
			}
		},
		getFolder: function (identifier, parameters, filtering, doCancelPreviousRequest = true, doCancelAllOtherRequests = true) {
			this.stopPollingDataLoad()
			let folderIdentifier = identifier
			if (!folderIdentifier) {
				folderIdentifier = 'root'
			}
			const isFiltering = filtering
			return this.service.getFolder(this.vendorId, folderIdentifier, parameters, doCancelPreviousRequest, doCancelAllOtherRequests).then(fetchedFolder => {
				if (!isFiltering) {
					this.treatGetFolderWhenNotFiltering(fetchedFolder, parameters)
				} else {
					this.treatGetFolderWhenFiltering(fetchedFolder)
				}
				return fetchedFolder
			})
		},
		treatGetFolderWhenNotFiltering: function (fetchedFolder, parameters) {
			this.searchResults = null
			let newSelectedFolder
			if (!fetchedFolder) {
				newSelectedFolder = this.treeStructure
			} else {
				this.service.addOrUpdateNode(fetchedFolder)
				if (
					!this.folderHash ||
					this.folderHash != fetchedFolder.hash ||
					!this.selectedFolder ||
					(this.selectedFolder.id != fetchedFolder.id && this.selectedFolder.hash != fetchedFolder.hash && this.selectedFolder.alias != fetchedFolder.alias) ||
					this.isFromSearch
				) {
					newSelectedFolder = fetchedFolder
				}
			}
			if ((newSelectedFolder && this.selectedFolder === null) || this.isFromSearch) {
				this.selectFolder(newSelectedFolder)
				this.isFromSearch = false
			}
			this.startPollingDataLoad(parameters)
		},
		treatGetFolderWhenFiltering: function (fetchedFolder) {
			if (fetchedFolder) {
				const fakedFolder = new Folder(fetchedFolder)
				if (fetchedFolder.documents) {
					fakedFolder.documents = fetchedFolder.documents.map(document => new Document(document))
				}
				if (fetchedFolder.folders) {
					fakedFolder.folders = fetchedFolder.folders.map(folder => new Folder(folder))
				}
				this.searchResults = fakedFolder
			}
		},
		updateURL: function () {
			const newHash = this.selectedFolder?.hash
			if (newHash != this.folderHash) {
				this.appService.goTo(
					{
						name: 'documents',
						params: { accounting_firm_id: this.accountingFirmId, vendor_id: this.vendorId, folderHash: newHash }
					},
					!this.folderHash
				)
			}
		},
		selectFolder: function (folder) {
			this.selectedFolder = folder
			this.selection = this.selectedFolder
			this.setDocuments(this.selectedFolder?.documents ?? [])
			this.setCurrentDocumentByHash(this.documentHash)
		},
		updateSelectedElements: function (elements) {
			let newSelection
			if (elements && Array.isArray(elements)) {
				newSelection = elements
			} else if (elements) {
				newSelection = [elements]
			} else {
				newSelection = []
			}
			this.manualShowDetails = true
			if (newSelection.length > 0) {
				this.addDocument(newSelection[0])
				this.setCurrentSelection(newSelection[0])
			}
			this.selectedElements.splice(0, this.selectedElements.length, ...newSelection)
		},
		clearSelectedFolder: function () {
			this.selectFolder(null)
		},
		clearSelectedElements: function () {
			this.updateSelectedElements()
		},
		showDetailsOnMobileDetails: function () {
			this.showDetailsOnMobile = true
		},
		selectionStart: function () {
			this.selectionInProgress = true
		},
		selectionStop: function () {
			this.selectionInProgress = false
		},
		reset: function () {
			this.reseting = true
			this.stopPollingDataLoad()
			this.clearSelectedFolder()
			this.clearSelectedElements()
			this.resetWorkflows()
			return this.service.reset().finally(() => {
				this.reseting = false
			})
		},
		toggleDetails: function () {
			this.manualShowDetails = !this.manualShowDetails
		},
		onDeletionStarted: function () {
			this.stopPollingDataLoad()
			this.clearSelectedElements()
		},
		onUploadStarted: function () {
			this.stopPollingDataLoad()
		},
		getTreeStructureIsBuilding: async function () {
			let treeStructures = await TreeStructureService.getTreeStructures(this.vendorId)
			this.structureIsBuilding = treeStructures ? treeStructures[0].status !== 'done' : true
		}
	}
}
</script>

<style scoped>
.item-details-panel {
	z-index: 2;
	position: relative;
}
</style>
