const DocumentsStructuresManager = () => import('@/components/SuperAdmin/Documents/DocumentsStructuresManager')
const DocumentsStructuresDetails = () => import('@/components/SuperAdmin/Documents/DocumentsStructuresDetails')
const DocumentsStructuresVisualisation = () => import('@/components/SuperAdmin/Documents/DocumentsStructuresVisualisation')

export default [
	{
		component: DocumentsStructuresManager,
		name: 'sa-documents-manager',
		path: 'documents/manager',
		redirect: { name: 'sa-documents-manager-details' },
		children: [
			{
				component: DocumentsStructuresDetails,
				name: 'sa-documents-manager-details',
				path: 'details/:structureId?',
				props: route => {
					return {
						value: parseInt(route.params?.structureId)
					}
				}
			},
			{
				component: DocumentsStructuresVisualisation,
				name: 'sa-documents-manager-visualisation',
				path: 'visualisation/:structureId?',
				// eslint-disable-next-line sonarjs/no-identical-functions
				props: route => {
					return {
						value: parseInt(route.params?.structureId)
					}
				}
			}
		],
	}
]
